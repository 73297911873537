import React, { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import FooterMain from '../FooterMain'
import TopBar from '../TopBar'
import LiveChatIcon from '../LiveChatIcon/'
import LiveChat from 'react-livechat'
import '../../styles/index.scss'

export default ({ children, topSpacing, pageId, metaData }) => {
  const [liveChatAvailable, setliveChatAvailable] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [outOfOffice, setOutOfOffice] = useState(false)
  const [menuIsClicked, setMenuIsClicked] = useState(null)

  const menuRef = React.createRef(null)

  const getCurrentUTCTime = () => {
    const now = new Date()
    const utcTime = new Date(now.toISOString())
    return utcTime
  }

  const isWithinBusinessHours = currentTime => {
    const startTime = new Date(currentTime)
    startTime.setUTCHours(8, 30, 0, 0)

    const endTime = new Date(currentTime)
    endTime.setUTCHours(17, 0, 0, 0)
    return currentTime >= startTime && currentTime <= endTime
  }

  useEffect(() => {
    setIsMounted(true)
  })

  useEffect(() => {
    const currentTimeUTC = getCurrentUTCTime()
    setOutOfOffice(!isWithinBusinessHours(currentTimeUTC))
  })

  const openLiveChat = () => {
    window.LC_API.open_chat_window()
  }

  const onLiveChatStateChanged = data => {
    setliveChatAvailable(data !== 'offline')
  }

  const onLiveChatLoad = ({ agents_are_available: agentsAreAvailable }) => {
    if (agentsAreAvailable) {
      setliveChatAvailable(agentsAreAvailable())
    }
  }
  let language = 'is'
  if (
    typeof window !== 'undefined' &&
    document.location.pathname.startsWith('/en/')
  ) {
    language = 'en'
  }

  // Use default values if none are provided
  const img =
    metaData?.image ??
    'https://www.ljosleidarinn.is/static/0a3e6c06452abbddb412ac5d06c2a3f5/ba86f/landakort-1095x559-sida.jpg'
  const description =
    metaData?.description ??
    'Ljósleiðarinn byggir upp og rekur þétt ljósleiðaranet um Ísland. Ljósleiðarinn gerir allt mögulegt mögulegt.'
  const title = metaData?.title ?? 'Ljósleiðarinn'
  const mappedMetaData = {
    ...metaData,
    'og:site_name': 'Ljósleiðarinn',
    'og:url': 'https://www.ljosleidarinn.is/',
    'og:type': 'website',
    title: title,
    'og:title': title,
    keywords: metaData?.keywords ?? 'ljósleiðarinn,gr,gagnaveita',
    description: description,
    'og:description': description,
    'twitter:description': description,
    'og:image': img,
    'og:image:secure': img,
    'twitter:image': img,
  }

  const clickHandler = () => {
    setMenuIsClicked(null)
  }

  return (
    <Fragment>
      <Helmet>
        <html lang={language} />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="google-site-verification"
          content="kDFsifZIrrQ_sEd_Pm8n4DM8UhmU0pQLO6a-MW4QFJs"
        />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="256x256"
          href="/android-chrome-256x256.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-chrome-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta
          name="msapplication-TileImage"
          content="/mstile-150x150.png"
        ></meta>

        {Object.keys(mappedMetaData).map(key => (
          <meta key={key} property={key} content={mappedMetaData[key]} />
        ))}
        {pageId && <meta name="pageId" content={pageId} />}
        <title>Ljósleiðarinn</title>
      </Helmet>

      {/* eslint-disable-next-line */}
      <div
        onClick={() => clickHandler()}
        className="main-wrap"
        style={{ paddingTop: topSpacing ? 120 : 0 }}
      >
        <div className="content">
          {children}
          <TopBar
            ref={menuRef}
            menuIsClicked={menuIsClicked}
            setMenuIsClicked={setMenuIsClicked}
          />
        </div>
      </div>

      <FooterMain />

      {/* Root element of PhotoSwipe. Must have class pswp. */}
      <div className="pswp" tabIndex="-1" role="dialog" aria-hidden="true">
        {/* Background of PhotoSwipe.
         It's a separate element, as animating opacity is faster than rgba(). */}
        <div className="pswp__bg" />
        {/* Slides wrapper with overflow:hidden. */}
        <div className="pswp__scroll-wrap">
          {/* Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. */}
          {/* don't modify these 3 pswp__item elements, data is added later on. */}
          <div className="pswp__container">
            <div className="pswp__item" />
            <div className="pswp__item" />
            <div className="pswp__item" />
          </div>
          {/* Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. */}
          <div className="pswp__ui pswp__ui--hidden">
            <div className="pswp__top-bar">
              {/*  Controls are self-explanatory. Order can be changed. */}
              <div className="pswp__counter" />
              <button
                className="pswp__button pswp__button--close"
                title="Close (Esc)"
              />
              <button
                className="pswp__button pswp__button--share"
                title="Share"
              />
              <button
                className="pswp__button pswp__button--fs"
                title="Toggle fullscreen"
              />
              <button
                className="pswp__button pswp__button--zoom"
                title="Zoom in/out"
              />
              {/* Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR */}
              {/* element will get class pswp__preloader--active when preloader is running */}
              <div className="pswp__preloader">
                <div className="pswp__preloader__icn">
                  <div className="pswp__preloader__cut">
                    <div className="pswp__preloader__donut" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
              <div className="pswp__share-tooltip" />
            </div>
            <button
              className="pswp__button pswp__button--arrow--left"
              title="Previous (arrow left)"
            />
            <button
              className="pswp__button pswp__button--arrow--right"
              title="Next (arrow right)"
            />
            <div className="pswp__caption">
              <div className="pswp__caption__center" />
            </div>
          </div>
        </div>
      </div>
      {isMounted &&
        (outOfOffice ? (
          <script
            data-jsd-embedded
            data-key="ce960b8b-00e3-444b-b42c-c291f0b7459d"
            data-base-url="https://jsd-widget.atlassian.com"
            src="https://jsd-widget.atlassian.com/assets/embed.js"
          ></script>
        ) : (
          <>
            <LiveChatIcon active={liveChatAvailable} onClick={openLiveChat} />
            <LiveChat
              onChatStateChanged={onLiveChatStateChanged}
              onChatLoaded={onLiveChatLoad}
              license={6827311}
            />
          </>
        ))}
    </Fragment>
  )
}
